

const Loading = (props:any) =>{

    return (<>
        <div>{props.text}</div>
        <div className="loader"></div>
    </>)
}

export default Loading;