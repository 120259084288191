import { useWeb3React } from '@web3-react/core';
import testImg from '../../img/imageUnavailable.jpg'
let serverUrl = window.location.origin;

interface MintFinish {
    data: any;
}

const MintFinish = (props: MintFinish) => {

    const { active, account, library, connector, activate, deactivate, chainId } = useWeb3React();

    const goHome = () => {
        window.location.href = serverUrl + "/"
    };


    return (<>

        <div className="connected-wallet">Conected wallet | <span className="address">{account}</span></div>

        <div className="progress">
            <div className="step done">
                Connect wallet
            </div>
            <div className="step done">
                Mint NFT
            </div>
            <div className="step active">
                View BlueMarked
            </div>
        </div>

        <div>
            <p className="success-message">
                <strong>Congratulations!</strong>
                <br />
                Check your NFT on <a href={props.data.link} target="_blank">etherscan</a>.
            </p>

            <div className="verify-image">
                <img src={(props.data.is_picture) ? props.data.imgUrl : testImg}></img>
            </div>

            <div className="summary">
                <div >
                    <label className="mint-label">NFT ID</label> 
                    <p>{props.data.nftId} </p>
                </div>

                <div >
                    <label className="mint-label">ASSET ID</label> 
                    <p>{props.data.assetId} </p>
                </div>

                <div >
                    <label className="mint-label">AUTHOR ADDRESS</label> 
                    <p>{props.data.authorADDRESS} </p>
                </div>
                
            </div>
        </div>

        <div>
            <button className="btn btn-action" onClick={goHome}>
                BACK TO HOME
            </button>
        </div>

    </>)
}

export default MintFinish;