import { useNavigate } from "react-router-dom";

let serverUrl = window.location.origin;

const BlueMark = () => {

    const navigate = useNavigate();

    const goVerify = () => {
        navigate("/verify");
        //window.location.href = serverUrl + "/verify"
    };

    const goGet = () => {
        navigate("/protect");
    };

    const goMint = () => {
        navigate("/mint");
    };

    return (
   
        <div className="section">
            {/* BlueMark logo */}  
            <div className="div-4">
                <picture>
                    <source
                    srcSet="https://cdn.builder.io/api/v1/image/assets%2FTEMP%2Fce8d00c532244e189cfcfd5fe17b7f95?format=webp&width=100 100w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2Fce8d00c532244e189cfcfd5fe17b7f95?format=webp&width=200 200w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2Fce8d00c532244e189cfcfd5fe17b7f95?format=webp&width=400 400w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2Fce8d00c532244e189cfcfd5fe17b7f95?format=webp&width=800 800w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2Fce8d00c532244e189cfcfd5fe17b7f95?format=webp&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2Fce8d00c532244e189cfcfd5fe17b7f95?format=webp&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2Fce8d00c532244e189cfcfd5fe17b7f95?format=webp&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2Fce8d00c532244e189cfcfd5fe17b7f95"
                    type="image/webp"
                    />
                    <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets%2FTEMP%2Fce8d00c532244e189cfcfd5fe17b7f95"
                    srcSet="https://cdn.builder.io/api/v1/image/assets%2FTEMP%2Fce8d00c532244e189cfcfd5fe17b7f95?width=100 100w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2Fce8d00c532244e189cfcfd5fe17b7f95?width=200 200w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2Fce8d00c532244e189cfcfd5fe17b7f95?width=400 400w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2Fce8d00c532244e189cfcfd5fe17b7f95?width=800 800w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2Fce8d00c532244e189cfcfd5fe17b7f95?width=1200 1200w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2Fce8d00c532244e189cfcfd5fe17b7f95?width=1600 1600w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2Fce8d00c532244e189cfcfd5fe17b7f95?width=2000 2000w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2Fce8d00c532244e189cfcfd5fe17b7f95"
                    className="image"
                    />
                </picture>
                <div className="builder-image-sizer image-sizer" />
            </div>

            <div className="div-5">
                <button className="btn btn-verify" onClick={goVerify}>
                    <p className="btn-title">
                        <strong>VERIFY</strong>
                    </p>

                    <p className="btn-desc">
                        a proof of autorship and make sure that NFT is legit.
                    </p>
                </button>
                
                
                <div className="p-title">
                    <p>
                        <strong>Protecting your NFT is a 2 steps process</strong>
                    </p>
                </div>
            

                <div className="main-action">

                    <button className="btn btn-getmint" onClick={goGet}>
                        <p className="btn-title">
                            <strong>1.GET</strong>
                        </p>

                        <p className="btn-desc">
                            proof of authorship to protect your work.
                        </p>
                    </button>

                    <button className="btn btn-getmint" onClick={goMint}>
                        <p className="btn-title">
                            <strong>2.MINT</strong>
                        </p>

                        <p className="btn-desc">
                        a NFT for which you have the proof of authorship.
                        </p>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default BlueMark;