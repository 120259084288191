import { useEffect, useState } from "react";
import MintFinish from "./MintFinish";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useWeb3React } from "@web3-react/core";
import { ABI } from "../web3/contract_abi";
import { GetWeb3Provider, TxHashObject } from "../web3/web3_functions";
import { MetamaskConnector, ResetWalletconnectConnector, WalletconnectConnector } from "../web3/connectors";
import { initWeb3 } from "../web3/web3_functions";
import Web3 from "web3";
import Loading from "../Loading/Loading";
import { useNavigate } from "react-router-dom";

interface MintProps {
    asset_id: any
}

let serverUrl = window.location.origin;

// Form elements for user
const initialValues = {
    assetId: "",
    name: "",
    cId: "",
    externalUrl: "",
    desc: "",

}

// SET WEB3 and CONTRACT PARAMS
const web3 = new Web3(window.ethereum);

const Mint = (props: MintProps) => {

    let initValues = initialValues
    if (props.asset_id !== "") {
        initValues = { ...initValues, assetId: props.asset_id }
    }

    const [values, setValues] = useState(initValues);
    const [mintdata, setMintdata] = useState({});
    const [loading, setLoading] = useState(false);
    const notify = (err: any) => toast.error(err, { theme: "colored" });
    const navigate = useNavigate();

    const { active, account, library, connector, activate, deactivate, chainId } = useWeb3React();

    const [connecting, setConnecting] = useState(false);

    const handleInputChange = (e: any) => {
        var { name, value } = e.target;

        //Desctructing to get values
        setValues({
            ...values,
            [name]: value,
        });

    };

    useEffect(() => {
        if (connecting) {
            setConnecting(false);
            handleSubmit();
        }
    }, [connecting])

    const connectMetamaskSimple = async () => {
        try {
            await activate(MetamaskConnector);
            initWeb3();
            // refresh this component
            setConnecting(true);
        } catch (ex) {
            console.log(ex);
        }
    };

    const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
        setLoading(true);
        // Convert asset id into crypto-js object (WordArray)
        let assetIdWeb3: any = web3.eth.abi.encodeParameter('bytes32', "0x" + values.assetId);

        // CRITICAL CODE. DO NOT TOUCH
        if (e) {
            e.preventDefault();
        }

        // Check all fields
        //console.log(initialValues);
        if (!chainId) {
            await connectMetamaskSimple();
            return;
        }


        // VALIDATE 
        if (active && values.assetId !== "") {
            //props.onMint(null, false, true);

            try {
                const smartContract = new web3.eth.Contract(ABI, "0xBF704c92A0341015d44ef1C0569638b55a70Afa7");

                // let stmtId: string = getStatementId("");
                // if (stmtId == "")
                //   return
                // NFT ID JE COMPUTED: bytes.concat(bytes20(sender), assetId);
                // AH: ja, vendar v trenutni verziji ni izracunan tako
                // Pomembno je, da je address v "from" enak addressu, ki je bil uporabljen pri timestamp. Account argument je lahko poljuben (now owner NFT-ja)
                //https://api.openft.world

                let mintAction: any

                // basic
                mintAction = smartContract.methods.safeMint1(account, assetIdWeb3, "https://nft.mynext.id").send({ from: account });

                mintAction.then((res: any) => {

                    try {
                        //console.log("RES MINT: \n", res);

                        // add metadata to database
                        let formData = {
                            "asset_id": values.assetId,
                            "metadata": values,
                        }

                        var url = serverUrl + '/api/v1/nft?type=mint';

                        // fetch data (do REST request)
                        fetch(url, {
                            method: "POST",
                            headers: {
                                accept: 'application.json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(formData)
                        })
                            .then(async response => {

                                // check for error response
                                if (!response.ok) {
                                    // get error message from body or default to response status
                                    console.log("Error in response code")
                                    const error = await response.text();
                                    return Promise.reject(error);

                                } else {

                                    const data1 = await response.text();
                                    const data = JSON.parse(data1);

                                    //console.log(data);

                                    if (data.success) {
                                        // Update page
                                        //console.log("mint res - ", res);
                                        setLoading(false);
                                        setMintdata({
                                            "assetId": values.assetId,
                                            "nftId": data.response.nft_id,
                                            "imgUrl": serverUrl + "/api/v1/image/" + values.assetId + "",
                                            "link": "https://goerli.etherscan.io/tx/" + res.transactionHash,
                                            "authorADDRESS": account,
                                            "is_picture": data.response.is_picture,
                                        });

                                    } else {
                                        throw new Error(data1);
                                    }

                                }
                            })
                            .catch(error => {
                                console.error("Error in API Issue: ", error);
                                setLoading(false);
                                //that.state.callback("Error", error.message + ".",true);
                            });


                    } catch (error: any) {
                        throw error;
                    }

                }).catch((err: any) => {
                    console.log(err);
                    notify("Transaction rejected")
                    setLoading(false);
                });

            } catch (err: any) {
                console.log("In catch - error", err);
                setLoading(false);
                notify(err.toString())
            }
        }
    }

    return (<>
        <ToastContainer />

        <div className="section">

            <h1 className="logo-title" onClick={() => { navigate("/") }}>Get <span className="logo-blue">Blue</span>Mark</h1>

            {isObjEmpty(mintdata) ? <>

                <div className="connected-wallet">Conected wallet | <span className="address">{account}</span></div>

                <div className="progress">
                    <div className="step done">
                        Connect wallet
                    </div>
                    <div className="step active">
                        Mint NFT
                    </div>
                    <div className="step">
                        View BlueMarked
                    </div>
                </div>

                {loading ? <Loading text="Waiting for transaction ..." /> :

                    <form className="box" method="get" action="" encType="multipart/form-data" onSubmit={handleSubmit}>

                        <div className="group-container">

                            <div className="form-group ">
                                <input className="form-input" type="text" id="form_name" required maxLength={200} placeholder="Asset ID" name="assetId" value={values.assetId} onChange={handleInputChange} />
                            </div>

                            <div className="form-group ">
                                <input className="form-input" type="text" id="form_name" required maxLength={60} placeholder="Item Name" name="name" value={values.name} onChange={handleInputChange} />
                            </div>

                            <div className="form-group ">
                                <input className="form-input" type="text" id="form_name" required maxLength={60} placeholder="CID" name="cId" value={values.cId} onChange={handleInputChange} />
                            </div>

                            <div className="form-group ">
                                <input className="form-input" type="text" id="form_name" maxLength={60} placeholder="External URL" name="externalUrl" value={values.externalUrl} onChange={handleInputChange} />
                            </div>

                            <div className="form-group ">
                                <input className="form-input" type="text" id="form_name" required maxLength={60} placeholder="Desciption" name="desc" value={values.desc} onChange={handleInputChange} />
                            </div>

                            <div>
                                <button type='submit' className="btn btn-action">
                                    MINT BLUEMARKED NFT
                                </button>
                            </div>
                        </div>
                    </form>
                }

            </> : <>
                <MintFinish data={mintdata} />
            </>}
        </div>

    </>)
}

export default Mint;

function isObjEmpty(obj: any) {
    return Object.keys(obj).length === 0;
}