import {WalletConnectConnector} from "@web3-react/walletconnect-connector";
import {InjectedConnector} from "@web3-react/injected-connector";

const RPC_URLS = {
    1: "https://mainnet.infura.io/v3/d88f8f1f9f3042b68a6e7896d40d5513",
    3: "https://ropsten.infura.io/v3/d88f8f1f9f3042b68a6e7896d40d5513",
    4: "https://ropsten.infura.io/v3/d88f8f1f9f3042b68a6e7896d40d5513",
    5: "https://goerli.infura.io/v3/d88f8f1f9f3042b68a6e7896d40d5513"
}

//METAMASK
export const MetamaskConnector = new InjectedConnector({
    supportedChainIds: [ 1, 3, 4, 5, 42 ]
});

// WALLET CONNECT
export const WalletconnectConnector = new WalletConnectConnector({
    rpc: RPC_URLS[4],
    bridge: "https://bridge.walletconnect.org",
    qrcode: true,
});

// added so that wallet connect window opens after you closed it the first time
export function ResetWalletconnectConnector(connector: WalletConnectConnector) {
    if (connector) {
        connector.walletConnectProvider = undefined;
    }
}