import { useWeb3React } from "@web3-react/core";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClone } from '@fortawesome/free-regular-svg-icons'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface ProtectFinishProps{
    data : any,
    asset_id: (asset_id:any) => void
}

const ProtectFinish = (props:ProtectFinishProps) => {
    const {active, account, library, connector, activate, deactivate, chainId} = useWeb3React();
    const notify = (err:any) => toast.success(err,{theme:"colored"});
    const navigate = useNavigate();
    
    const goMint = () => {
        props.asset_id(props.data.assetId)
        navigate("/mint");
    };

    const goHome = () => {
        navigate("/")
    };

    return (

        <div>
            <ToastContainer />
            <div className="connected-wallet">Conected wallet | <span className="address">{account}</span></div>

            <div className="progress">
                <div className="step done">
                    Connect wallet
                </div>
                <div className="step done">
                    Record proof
                </div>
                <div className="step active">
                    Get BlueMark
                </div>
            </div>

            <div className="success-message">
                <p>
                    <strong>Congratulations!</strong>
                    <br />
                    Check your NFT on <a href={props.data.link} target="_blank">etherscan</a>.
                </p>
                <p className="text-red">Don't forget to store your ASSET ID in a secure place</p>
            </div>

            <div className="summary">
                <div >
                    <label className="mint-label">NFT ID</label> 
                    <p>{props.data.nftId} </p>
                </div>

                <div >
                    <label className="mint-label">ASSET ID<span><FontAwesomeIcon icon={faClone}   className="copyNFT"  onClick={() =>  {navigator.clipboard.writeText(props.data.assetId); notify("ASSET ID copied to clipboard")}} /></span></label> 
                    <p>{props.data.assetId} </p>
                </div>

                <div >
                    <label className="mint-label">AUTHOR ADDRESS</label> 
                    <p>{props.data.authorADDRESS} </p>
                </div>
              
            </div>

            <button className="btn btn-action" onClick={goMint}>
                MINT THIS NFT
            </button>

            <button className="btn btn-action" onClick={goHome}>
                BACK TO HOME
            </button>

        </div>
    )
}

export default ProtectFinish;