import React, { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SubmitHandler, useForm } from "react-hook-form";

import crypto from "crypto-js"
import { useWeb3React } from "@web3-react/core";
import { ABI } from "../web3/contract_abi";
import Web3 from "web3";
import { ethers } from "ethers";
import { GetWeb3Provider, TxHashObject } from "../web3/web3_functions";
import {MetamaskConnector, ResetWalletconnectConnector, WalletconnectConnector} from "../web3/connectors";
import {initWeb3} from "../web3/web3_functions";
import { Buffer } from 'buffer';
import Loading from "../Loading/Loading";

let serverUrl = window.location.origin;

interface UploadProps{
    uploadFinish: (uploadData:any) => void,
    nftData: (nftData:any) => void
}

type FormInputs = {
    file: FileList,
};

// SET WEB3 and CONTRACT PARAMS
const web3 = new Web3(window.ethereum);

const Upload = (props:UploadProps) => {

    const [assetID,setAssetID] = useState('');
    const [fileName, setFileName]= useState("");
    const [loading, setLoading] = useState(false);
    const notify = (err:any) => toast.error(err,{theme:"colored"});

    const {active, account, library, connector, activate, deactivate, chainId} = useWeb3React();

    const {register, handleSubmit, setError, formState: {errors}} = useForm<FormInputs>();

    function onlyLettersAndNumbers(str:any) {
        if(str.indexOf(":") !== -1){
            let element = str.split(":")[1];
            return /^[A-Za-z0-9]*$/.test(element);
        }

        return /^[A-Za-z0-9]*$/.test(str);
    }

    const onSubmitForm: SubmitHandler<FormInputs> = (data: FormInputs) => {

        //props.uploadFinish(true);

        if(fileName !== ""){
            // VALIDATE FILE SIZE < 2MB
            
            if (data.file.item(0)!.size > 2000000) {
                notify("File too large. File size must be under 2MB.");

            } else {
                // READ FILE CONTENT
                let fileReader = new FileReader();
                fileReader.readAsArrayBuffer(data.file.item(0) as Blob);
                fileReader.onload = async (e) => {
                    try {
                        const fileContent: string | undefined = fileReader.result as string;
                            if (!fileContent || fileContent === "") {
                                notify("Missing file content." )
                                return
                            }
                            
                            let newfileContent = Buffer.from(fileContent).toString('base64');
                            CreateNFT(newfileContent);
                    } catch (e) {
                        console.log(e);
                        notify("There is something wrong with file content. Try uploading other file.")
                    }
                }
            }
        }else{
            console.log("Protected serial ");
            if(assetID.length < 6){
                notify("Serial number  must contain at least six characters.")
            }
            else if(!onlyLettersAndNumbers(assetID)){
                notify("Serial number  must contain only numbers and letters.")
            }else{
                CreateNFT("");
            }
        }
    }

    async function CreateNFT(fileContent:any) {
        
        console.log("before connectiong to web3");
        setLoading(true);

        let generated_hash;
        let typeOfData: string;

        // If asset id (serial number) is given, use it else generate it from file content
        let serialNumber = assetID;
        if(assetID !== ""){
            typeOfData = "SN";
            console.log("Generating with serial number");
            let generated_hashA: crypto.lib.WordArray = crypto.SHA256(serialNumber);
            generated_hash = generated_hashA;

        } else{
            typeOfData = "file";
            console.log("Generating file content ",fileContent,typeof fileContent);
            let generated_hashB: crypto.lib.WordArray = crypto.SHA256(fileContent);
            generated_hash = generated_hashB;
        }
    
        var _alice = null;

        if (account !== null && account !== undefined) {
            _alice = crypto.enc.Hex.parse(account.replace('0x', ''));
        } 
        
        if(_alice !== null){


            var _nid = _alice.concat(generated_hash);
            let _nftId = crypto.SHA256(_nid);

            // Generated hash zamenjaj z assetId (poimenovanje)
            let assetId = generated_hash.toString();
           /* console.log("xx generated_hash", generated_hash.toString());
            console.log("xx _alice", _alice.toString());
            console.log("xx _nid", _nid.toString());
            console.log("xx nft id", _nftId.toString());
            */

            // Pretvoris v obliko za SC
            let nftId: any = web3.eth.abi.encodeParameter('bytes32', '0x' + _nftId.toString());
        
            /*
            console.log("ASSET_ID: ", _alice.toString());
            console.log("NFT_ID: ", nftId);
            */

            //props.onId(assetId, true);

            const smartContract = new web3.eth.Contract(ABI, "0xBF704c92A0341015d44ef1C0569638b55a70Afa7");
        
            smartContract.methods.timestamp(nftId).send({ from: account }).then((res: TxHashObject) => {

                if (!res.status) {
                    notify("Error when sending transaction")
                    setLoading(false);
                    //props.onId(assetId, false);
                    return;
                } else {

                    
                    //Successfully timestamped - time to save data 
                    let formData = {
                        "asset_id": assetId,
                        "address": account,
                        "file":fileContent,
                        "file_name": fileName,
                        "serial_number": serialNumber,
                        "nft_id" : nftId,
                        "chain_id": chainId,

                        //"name": values.mintName,
                        //"description": values.mintDesc,
                        //"external_url": values.mintUrl,
                        //"image": "https://nft.mynext.id/v1/image/"+nftId,
                    }

                    var url = serverUrl + '/api/v1/nft?type=protect';

                    // fetch data (do REST request)
                    fetch(url,{
                        method:"POST",
                        headers: {
                          accept: 'application.json',
                          'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(formData)
                    })
                    .then(async response => {
                
                        // check for error response
                        if (!response.ok ) {
                            // get error message from body or default to response status
                            console.log("Error in response code")
                            const error = await response.text();
                            return Promise.reject(error);

                        }else{
                
                            const data1 = await response.text();
                            const data = JSON.parse(data1);
                            
                            if(data.success) {
                                

                                // Continue flow to mint stage 
                                props.uploadFinish(true);

                                props.nftData({
                                    "nftId":nftId,
                                    "assetId":assetId,
                                    "authorADDRESS":account,
                                    "link":"https://goerli.etherscan.io/tx/" + res.transactionHash,
                                });
                                
                                setLoading(false);
                                
                            } else {
                                throw new Error(data1);
                            }

                        }
                    })
                    .catch(error => {
                        console.error("Error in API Issue: ", error);
                        setLoading(false);
                        notify(error.toString())
                    });
                }
            }).catch((err: any) => {
                console.log(err);
                notify("Transaction rejected")
                setLoading(false);
            });
        }

        
    }

    return (
        <div>
            <ToastContainer />
            <div className="connected-wallet">Conected wallet | <span className="address">{account}</span></div>

            <div className="progress">
                <div className="step done">
                    Connect wallet
                </div>
                <div className="step active">
                    Record proof
                </div>
                <div className="step">
                    Get BlueMark
                </div>
            </div>

            {loading ? <Loading text="Waiting for transaction ..."/> :
            
                <form className="box" method="get" action="" encType="multipart/form-data" onSubmit={handleSubmit(onSubmitForm)}>

                    <div className="box__input">
                        <span className="drop-title">Drag your art/craft/work or click to upload (max. file size 2MB)</span>

                        <input  id="imagesUpload"
                                {...register("file")}
                                type="file"
                                onChange={(e:any) => {

                                if(e.target.files[0] !== undefined){
                                    setFileName(e.target.files[0].name);
                                    //setEmptyUpload(false);
                                }else{
                                    setFileName("");
                                    //setEmptyUpload(true);
                                }
                                }}
                        />
                        <p id="fileName">{fileName}</p>

                    </div>
                    
                    <div className="or"><span>or</span></div>

                    <input type="text" id="serialNum" placeholder="Enter a serial number (6 characters max.)"  name="asset"  value={assetID} onChange={event => {

                        if(event.target.value !== ""){
                            setAssetID(event.target.value)
                            //setEmptyUpload(false);
                        }else{
                            //setEmptyUpload(true);
                            setAssetID("")
                        }

                    }}/>

                    <div>
                        <button type='submit' className="btn btn-action">
                            GET BLUEMARK
                        </button>
                    </div>
                </form>
            }
        </div>
    )
}

export default Upload;