import { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import BlueMark from "./BlueMark";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import Mint from "./Mint/Mint";
import ProtectStart from "./Protect/ProtectStart";
import PrivacyPolicy from "./Terms&PrivacyPolicy/Privacy_policy";
import TermsConditions from "./Terms&PrivacyPolicy/Terms&conditions";
import Verify from "./Verify/Verify";


let serverpath = window.location.pathname;


const LandingPage = () => {

    const [assetData, setAssetData] = useState("");

    return (
            <BrowserRouter key="Router">
                <div className="container">
                    <Header />

                    <Routes>
                        <Route path='/' element={<BlueMark />}></Route>
                        <Route path='/verify' element={<Verify />}></Route>
                        <Route path='/protect' element={<ProtectStart  
                            assetID={(asset_id) => {
                            setAssetData(asset_id);
                            }}
                        />}></Route>
                        <Route path='/mint' element={<Mint  asset_id={assetData}/>}></Route>
                        <Route path='/terms' element={<TermsConditions />}></Route>
                        <Route path='/privacy' element={<PrivacyPolicy />}></Route>
                    </Routes>

                    <Footer />
                </div>
            </BrowserRouter>
    )
}

export default LandingPage;