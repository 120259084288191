import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ConnectWallet from "./ConnectWallet";
import ProtectFinish from "./ProtectFinish";
import Upload from "./Upload";
let serverUrl = window.location.origin;

interface ProtectStartProps {
    assetID: (asset_id:any) => void
}

const ProtectStart = (props:ProtectStartProps) => {

    const [connectWallet, setConnectWallet] = useState(false);
    const [nftData, setNftData] = useState({});
    const [blueMark, setBlueMark] = useState(false);
    const navigate = useNavigate();

    return(
        <div className="section">
            <h1 className="logo-title" onClick={ () => {navigate("/")}}>Get <span className="logo-blue">Blue</span>Mark</h1>

            {!connectWallet ?
            <>
                <ConnectWallet walletCon={(walletConnection) => {
                    setConnectWallet(walletConnection);
                }}

            />
            </>:<>
                {!blueMark ? <>
                
                    <Upload 
                    uploadFinish={(uploadData) => {
                        setBlueMark(uploadData);
                    }}
                    
                    nftData={(nftData) => {
                        setNftData(nftData);
                    }}
                    />
                
                </>:<>
                    <ProtectFinish data={nftData}
                    
                    asset_id={(asset_id) => {
                        props.assetID(asset_id);
                    }}

                    />
                </>}
                

            </>}
            
        </div>
    )
}

export default ProtectStart;