import { useWeb3React } from "@web3-react/core";
import { ABI } from '../web3/contract_abi';
import Web3 from "web3";
import { ethers } from "ethers";
import { sign } from "crypto";
import { GetWeb3Provider, TxHashObject } from "../web3/web3_functions";
import {MetamaskConnector, ResetWalletconnectConnector, WalletconnectConnector} from "../web3/connectors";
import {initWeb3} from "../web3/web3_functions";


import wallet from '../../img/wallet.png'

// SET WEB3 and CONTRACT PARAMS
const web3 = new Web3(window.ethereum);

interface ConnectWalletProps{
    walletCon: (walletConnection:any) => void
}

const ConnectWallet = (props:ConnectWalletProps) => {

    // Gives us access to Web3 context variables -- about your current account, MM connection,...
    const { active, account, library, connector, activate, deactivate, chainId } = useWeb3React()


    // WEB 3 functions -----------------------------------------------------
    const connectWalletConnectSimple = async () => {
        try {
            ResetWalletconnectConnector(WalletconnectConnector);
            await activate(WalletconnectConnector);
        } catch (ex) {
            console.log(ex);
        }
    };

    const connectMetamaskSimple = async () => {
        try {
            await activate(MetamaskConnector);
            initWeb3();
        } catch (ex) {
            console.log(ex);
        }
    };

    const disconnectFromMetamask = async () => {
        if (active) {
            try {
                await deactivate();
            } catch (ex) {
                console.error(ex);
            }
        }
    }

    const switchNetwork = async () => {
        try {
            await library.provider.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: "0x5" }]
            });
        } catch (e) {
            console.log(e)
        }
    }

    // --------------------------------------------------------------------

    const goWallet =()=>{

        ConnectWallet();
        //props.walletCon(true);

        console.log("connected");

    }

    async function ConnectWallet(){
        if(!web3 || !account){
            await connectMetamaskSimple();
        } 

        props.walletCon(true);
    }

    return (<>

        <div className="progress">
            <div className="step active">
                Connect wallet
            </div>
            <div className="step">
                Record proof
            </div>
            <div className="step">
                Get BlueMark
            </div>
        </div>

        <div className="protect">
            <img loading="lazy" src={wallet} alt="Wallet Image" height={'200px'} width={'200px'}/>
            <p>In order to get your BlueMark, you need to connect your wallet</p>
        </div>

        <div>
            <button className="btn btn-action" onClick={goWallet}>
                CONNECT WALLET
            </button>
        </div>
        
    </>)
}

export default ConnectWallet;


