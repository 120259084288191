import { useNavigate } from "react-router-dom";

const Footer = () => {
    
    const navigate = useNavigate();

    return (
        <div className="footer div-23">
            <div className="div-24">
                <div className="div-25">
                <div className="column-6">
                    <div className="div-26">
                        <div className="copy">
                            <strong >
                            © 2022 BlueMark{" "}
                            </strong>
                        </div>

                        <span > All rights reserved | <span className="clickable" onClick={() => {navigate("/terms")}}> Terms of use </span>|<span className="clickable" onClick={() => {navigate("/privacy")}}> Privacy policy</span></span>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;