import { useState, useEffect } from "react";
import VerifyFinish from "./VerifyFinish";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SubmitHandler, useForm } from "react-hook-form";


import crypto from "crypto-js"
import { useWeb3React } from "@web3-react/core";
import { ABI } from "../web3/contract_abi";
import Web3 from "web3";
import { ethers } from "ethers";
import { GetWeb3Provider, TxHashObject } from "../web3/web3_functions";
import {MetamaskConnector, ResetWalletconnectConnector, WalletconnectConnector} from "../web3/connectors";
import {initWeb3} from "../web3/web3_functions";
import { Buffer } from 'buffer';
import Loading from "../Loading/Loading";
import { useNavigate } from "react-router-dom";

let serverUrl = window.location.origin;

type FormInputs = {
    file: FileList,
};

// SET WEB3 and CONTRACT PARAMS
const web3 = new Web3(window.ethereum);

const Verify = () => {

    const [verify, setVerify] = useState(false);

    const [verifiedNft, setVerifiedNft] = useState({});

    const [fileName, setFileName]= useState("");
    const [loading, setLoading] = useState(false);
    const notify = (err:any) => toast.error(err,{theme:"colored"});
    const navigate = useNavigate();

   
    const [uploadedfile, setUploadedFile]= useState("");
    const [emptyUpload, setEmptyUpload] = useState(true);

    const [SN,setSN] = useState('');
    const [assetID,setAssetID] = useState('');
    const [addressID, setAddressID] = useState('');

    const {register, handleSubmit, setError, formState: {errors}} = useForm<FormInputs>();

    const {active, account, library, connector, activate, deactivate, chainId} = useWeb3React();

    // connection state, to allow component refresh when connecting to web3
    const [hasConnected, setHasConnected] = useState(active);
    const [fileContents, setFileContents] = useState("");

    useEffect(() => {
        if(hasConnected) {
           if(fileContents != ""){
                VerifyNFT(fileContents);
           }
          
          setHasConnected(false);
        }
      }, [hasConnected])


    const connectMetamaskSimple = async (fileContent: string) => {
        try {
            await activate(MetamaskConnector);
             initWeb3();
             setFileContents(fileContent);
             setHasConnected(true);
        } catch (ex) {
            console.log(ex);
        }
    };

    function onlyLettersAndNumbers(str:any) {
        if(str.indexOf(":") !== -1){
            let element = str.split(":")[1];
            return /^[A-Za-z0-9]*$/.test(element);
        }

        return /^[A-Za-z0-9]*$/.test(str);
    }

    const onSubmitForm: SubmitHandler<FormInputs> = (data: FormInputs) => {

        if(fileName !== ""){
            // VALIDATE FILE SIZE < 2MB
            
            if (data.file.item(0)!.size > 2000000) {
                notify("File too large. File size must be under 2MB.");

            } else {
                // READ FILE CONTENT
                let fileReader = new FileReader();
                fileReader.readAsArrayBuffer(data.file.item(0) as Blob);
                fileReader.onload = async (e) => {
                    try {
                        const fileContent: string | undefined = fileReader.result as string;
                            if (!fileContent || fileContent === "") {
                                notify("Missing file content." )
                                return
                            }
                            console.log("Protected with file ");
                            let newfileContent = Buffer.from(fileContent).toString('base64');
                            VerifyNFT(newfileContent);
                    } catch (e) {
                        console.log(e);
                        notify("There is something wrong with file content. Try uploading other file.")
                    }
                }
            }
        }else{
            console.log("Protected serial ");
            if(SN.length < 6){
                notify("Serial number  must contain at least six characters.")
            }
            else if(!onlyLettersAndNumbers(SN)){
                notify("Serial number  must contain only numbers and letters.")
            }else if(SN !== undefined){
                VerifyNFT(SN);
            }
        }
    }

    async function VerifyNFT(fileContent : string): Promise<void> {

        //console.log("file " + fileContent)
        console.log("-----", SN);
        if (!web3 || !library) {
            await connectMetamaskSimple(fileContent);
            return;
        } 

        // Wait until wallet is connected ! 

        
        let generated_hash: crypto.lib.WordArray;

        // If asset id is given, use it else generate it from file content
        if(SN !== ""){
            console.log("Generating with asset ID", SN);
            let generated_hashA: crypto.lib.WordArray = crypto.SHA256(SN);
            generated_hash = generated_hashA;

        } else{
            console.log("Generating file content");
            let generated_hashB: crypto.lib.WordArray = crypto.SHA256(fileContent);
            generated_hash = generated_hashB;
        }
         
        setAssetID(generated_hash.toString());
        // to stop errors
        let assetId = generated_hash.toString();

        // Get author address
        var url = serverUrl + '/api/v1/nft?id=' + assetId;

        let nftId = "";
        let addressID = ""

        // fetch author address  (do REST request)
        await fetch(url,{
            method:"GET",
            headers: {
                accept: 'application.json',
                'Content-Type': 'application/json'
            }
        })
        .then(async response => {
    
            // check for error response
            if (!response.ok ) {
                // get error message from body or default to response status
                console.log("Error in response code")
                const error = JSON.parse(await response.text());
                console.log(error);
                notify(error.reason);
            }else{
                const data1 = await response.text();
                const data = JSON.parse(data1);
                
                if(data.success) {
                    console.log(data)
                    addressID = data.response.address;
                    nftId = data.response.nft_id;
                     //Calculate via assetID
                    if(assetId !== ''  && addressID !==""){

                        try {
                
                            let assetID_Byte: any = web3.eth.abi.encodeParameter('bytes32', '0x' + assetId.toString());
                            const smartContract = new web3.eth.Contract(ABI, "0xBF704c92A0341015d44ef1C0569638b55a70Afa7");

                            smartContract.methods.getNftId(assetID_Byte).call().then(async (res: any) => {
                                let _alice = crypto.enc.Hex.parse(addressID.replace('0x', ''));
                                var _nid = _alice.concat(generated_hash);
                                let _nftId = crypto.SHA256(_nid);

                                if(res.toString() === '0x' + _nftId.toString()) {
                                    // Basic verification OK
                                    setVerify(true);
                                    setVerifiedNft(data.response);
                                    return
                                } else {
                                    notify( "Not verified");
                                    return
                                }

                            }).catch((err: any) => {
                                console.log(err);
                                notify(err.message);
                            });
                    
                        } catch (error: any) {
                
                            console.log(error)
                            notify(error.message);
                        }
            
                    }else{
                        notify("Empty asset ID or file");
                    }
 
                } else {
                    throw new Error(data1);
                }
            }
        })
        .catch(error => {
            console.error("Error in API Issue: ", error);

            if(error === "no rows in result set"){
                var el = document.getElementById("protect-error");
                if(el !== null){
                    el.style.display =  'block';
                }
            }

            notify(error.toString());
        });

       
    }

    return (
        <div className="section">
            <ToastContainer />

            <h1 className="logo-title" onClick={ () => {navigate("/")}}>Get <span className="logo-blue">Blue</span>Mark</h1>

            {!verify ? <>

                <div className="progress">
                    <div className="step active">
                    File to verify
                    </div>
                    <div className="step">
                    Verify BlueMark
                    </div>
                </div>

                <form className="box" method="post" action="" encType="multipart/form-data" onSubmit={handleSubmit(onSubmitForm)}>
                    <div className="box__input">
                        <input  id="imagesUpload"
                                {...register("file")}
                                type="file"
                                onChange={(e:any) => {

                                if(e.target.files[0] !== undefined){
                                    setFileName(e.target.files[0].name);
                                    setEmptyUpload(false);
                                }else{
                                    setFileName("");
                                    setEmptyUpload(true);
                                }
                                }}
                            />
                        <p id="fileName">{fileName}</p>
                    </div>
                    <div className="box__uploading">Uploading…</div>
                    <div className="box__success">Done!</div>
                    <div className="box__error">Error! <span></span>.</div>

                    <div className="or"><span>or</span></div>

                    <input type="text" placeholder="Enter a serial number (6 characters max.)" name="asset" className="verify-form" id="ts-assetID-verify"  value={SN} onChange={event => {
                    
                        if(event.target.value !== ""){
                            setSN(event.target.value)
                            setEmptyUpload(false);
                        }else{
                            setEmptyUpload(true);
                            setSN("")
                        }
                    }} /> 

                    <div>
                        <button type='submit' className="btn btn-action">
                            VERIFY
                        </button>
                    </div>
                </form>

            </>:<>
                <VerifyFinish nft={verifiedNft}/>
            </>}

            
        </div>
    );
}

export default Verify;