
import testImg from '../../img/imageUnavailable.jpg'
let serverUrl = window.location.origin;

interface VerifyFinishProps {
    nft: any
}

const VerifyFinish = (props: VerifyFinishProps) => {

    const goHome = () => {
        window.location.href = serverUrl + "/"
    };

    return (
        <div className='section'>
            <div className="progress">
                <div className="step done">
                File to verify
                </div>
                <div className="step active">
                Verify BlueMark
                </div>
            </div>

            <div>
                <p className="p-title">Title: {(props.nft.metadata?.name) ? props.nft.metadata.name : 'No name given'}</p>
                <p>{(props.nft.metadata?.desc) ? props.nft.metadata.desc : 'No description given'}</p>

                <div className="verify-image">
                    <img src={(props.nft.is_picture) ? serverUrl + '/api/v1/image/' + props.nft.asset_id : testImg}></img>
                </div>

                <div className="summary">
                    <div >
                        <label className="mint-label">NFT ID</label> 
                        <p>{props.nft.nft_id} </p>
                    </div>

                    <div >
                        <label className="mint-label">ASSET ID</label> 
                        <p>{props.nft.asset_id} </p>
                    </div>

                    <div >
                        <label className="mint-label">AUTHOR ADDRESS</label> 
                        <p>{props.nft.address} </p>
                    </div>
                </div>
            </div>

            <div>
                <button className="btn btn-action" onClick={goHome}>
                    BACK TO HOME
                </button>
            </div>
        </div>
    );
}

export default VerifyFinish;