import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {Web3ReactProvider} from "@web3-react/core";
import {Web3Provider} from "@ethersproject/providers";
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// each different provider has its own connector - depends on wallets
function getLibrary(provider?: any) : any {
    // ETHERS PROVIDER
    // const library = new ethers.providers.Web3Provider(provider);
    // library.pollingInterval = 8000; // frequency provider is polling
    // return library
    // WEB3 PROVIDER
    return new Web3Provider(provider);
}


root.render(
    <Web3ReactProvider getLibrary={getLibrary}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </Web3ReactProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
